import { GetStaticProps } from 'next';
import React from 'react';

import { Error404 } from '@klappir/container/graphcms';
import { getPage } from '@klappir/data/graphcms';
import { DataGraphcms } from '@klappir/types';

const Page = ({
  content,
  error404,
  params,
  preview,
}: DataGraphcms.PageProps) => {
  return <Error404 {...error404} />;
};

export const getStaticProps: GetStaticProps = async (context) => {
  const error404 = await getPage('error-404', context.locale);
  console.log('force deploy');
  return {
    props: { error404: error404 ?? {} },
  };
};

export default Page;
